import Vue from 'vue'
import axios from 'axios'
import router from '@/router/index.js'
import { ElMessage } from 'element-plus'
import store from '@/store'
import { VueAxios } from './axios'

import { ACCESS_TOKEN,REFRESH_TOKEN } from '@/store/mutation-types'
let flag = true

// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // api base_url
  timeout: 50000, // 请求超时时间
  withCredentials: true //跨域处理
})




const err = (error) => {
  if (error.response ) {
    const data = error.response.data
    const msg = error.response.message
    const token = sessionStorage.getItem(ACCESS_TOKEN)
    if (error.response.status === 403) {
      ElMessage.error(msg);
    }
    if(error.response.status === 401){
      router.push({
        path: "/login",
      });
      sessionStorage.setItem(ACCESS_TOKEN,'');
      sessionStorage.setItem(REFRESH_TOKEN,'');
      ElMessage.error('登录超时，请重新登录');
    }


    if ((error.response.status === 400) && !(data.result && data.result.isLogin)) {
      if (error.response.data.error_description == 'Bad credentials') {
        ElMessage.error('你输入的密码有误');
      } else {
        if(error.response.data.error_description){
          ElMessage.error(error.response.data.error_description);
        } 
       
      }

      if (token) {
        // store.dispatch('Logout').then(() => {
        //   setTimeout(() => {
        //     flag = true
        //     window.location.reload()
        //   }, 1500)
        // })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  if (token && config.url.indexOf('/verification/getGraphicCode') ==-1) {
    if (config.url.indexOf('/qm/') ==-1) {
      config.headers['Authorization'] = `Bearer ${token}` // 让每个请求携带自定义 token 请根据实际情况自行修改
    }


  }
  if (config.url.indexOf('xcel') != -1 || config.url.indexOf('Pdf')!= -1) config.responseType = 'blob'
  return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
  
  // 导出
  if (response.headers['content-type'].indexOf('application/vnd.ms-excel') > -1
    && !response.data.hasOwnProperty('responseCode')) {
    return response;
  }
  if (response.headers['content-type'].indexOf('application/pdf') > -1
    && !response.data.hasOwnProperty('responseCode')) {
    return response;
  }

  

 
  if (response.data.code > 200) {

    ElMessage.error(response.data.message);
    return Promise.reject(response)
  } else {
    return response.data
  }
}, err)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}
